import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import customer from "../assets/img/mert-haciosmanoglu.png"
import team1 from "../assets/img/main/donusum.jpg"
import uiUx from "../assets/img/ui-ux-design.png"
import cro from "../assets/img/convertion-rate-optimization.png"
import webDev from "../assets/img/website-development.png"
import LeadGen from "../assets/img/lead-generation.png"
import checkModal from "../assets/img/check-modal.png"



class Convert extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: false,
      value: ''
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    e.preventDefault();

    this.setState({ value: e.target.value });
  }
  showModal(e) {

    this.setState({ display: !this.state.display });
    document.querySelector('.check-modal').classList.add('active-modal');

    document.querySelector('#site').innerHTML = this.state.value;
  }

  closeModal() {
    this.setState({ display: !this.state.display })
    document.querySelector('.check-modal').classList.remove('active-modal');
  }
  handleSubmit(e) {

    e.preventDefault();
    const title = document.title.split('|',1).toString();  
    const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
      method: 'POST',
      body: JSON.stringify({
        "text_327649": title,
        "text_204202": document.querySelector('#site').value,
        "text_775908": document.querySelector('#name').value,
        "text_532192": document.querySelector('#email').value,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    });
    response.then(res => {
      if (res.status === 200) {
        document.querySelector('.status').innerHTML = "Mesajınız başarıyla gönderilmiştir.";
      }
      else {
        document.querySelector('.status').innerHTML = "Bir hata oluştu.";
      }
    });
  }
  render() {
    return (
      <Layout>
        <SEO title="Dönüşüm" description="Websitenize gelen trafiği, ödeme yapan birer müşteriye dönüştürüyoruz." />
        <div className="convert">
          <div className="convert-hero container" >
            <div className="col-md-4 noPadding">
              <div className="top">
                <h1>Dönüşüm</h1>
                <p>Websitenize gelen trafiği, ödeme yapan birer müşteriye dönüştürüyoruz. </p>
                <p onClick={(e) => this.showModal()} className="content-link">Ücretsiz marka analizi edinin.</p>
              </div>
              <div className="bottom">
                <h2>Liva Pastacılık</h2>
                <p>“Dijitalde markamızın görünürlüğünü artırarak daha fazla satış elde etmemizi sağlamanın yanı sıra her konuda bizi de sürekli olarak aydınlatmaya çalıştığınız için teşekkür ederiz.”</p>
                <div className="profile">
                  <div> <img alt="alt text" src={customer}></img></div>
                  <div><p>Mert Hacıosmanoğlu</p>
                    <p>Yönetim Kurulu Üyesi</p></div>
                </div>
              </div>
            </div>
            <div className="col-md-8 convert-image">
              <img alt="alt text" src={team1} />
              <div className="right-content">
                <div className="col-md-4">
                  <h3>Yatırım Geri Dönüşü</h3>
                  <p>Dijital çalışmalara yaptığınız yatırımın en verimli şekilde geri dönmesi için çalışıyoruz.</p>
                </div>
                <div className="col-md-4">
                  <h3>Rekabet Avantajı</h3>
                  <p>Çalışmalarımız sonucunda artan dönüşümlerle markanızın rekabette birkaç adım öne geçmesini sağlıyoruz.</p>
                </div>
                <div className="col-md-4">
                  <h3>Güçlü Marka İmajı</h3>
                  <p>Daha fazla tercih edilen bir marka olmanız için yürüttüğümüz çalışmalar, markanızı olumlu yönde etkiliyor.</p>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="convert-traffic py60">
          <h2>Trafiğinizi harekete <br />geçirecek dokunuşlar.</h2>
          <p><span>HİZMETLER <svg className="arrow-right" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 563 1061.4" space="preserve">
            <polygon points="123.1,0 0,100.7 356.3,536.2 0.3,958.9 121.9,1061.4 563,537.6 " />
          </svg></span> ANALİZ</p>
        </div>
        <div className="services-list__block container">
          <div className="convert-service">
            <div className="col-md-4">
              <h2>UI/UX<br /> Tasarım</h2>
              <p>Websitenizi kullanıcı deneyimi odağında iyileştiriyor ve dijital dinamiklere uygun, hızlı ve estetik hale getiriyoruz.</p>
              <p className="small-content">
                - Web ve mobil uyumluluk denetimi<br />
                - Tasarım trendlerine uygun arayüzü tasarımı<br />
                - Kullanıcı deneyimine uygun elementlerin geliştirilmesi
        </p>
              <p> <Link to="/ui-ux-tasarim/" className="content-link">UI/UX tasarım sürecine göz atın.</Link></p>
            </div>
            <div className="col-md-6">
              <img alt="alt text" src={uiUx}></img>
            </div>
          </div>

          <div className="convert-service" id="cro-optimizasyonu">
            <div className="col-md-6">
              <img alt="alt text" style={{ marginRight: 20 }} src={cro}></img>
            </div>
            <div className="col-md-4">
              <h2> Dönüşüm Oranı <br />Optimizasyonu (CRO)</h2>
              <p>Website trafiğinizi hedeflenen eylemi (satış, form doldurma, rezervasyon vb.) gerçekleştirmeye teşvik ediyor, websitenizden ayrılmadan önce müşteriye “dönüşme” oranını artırmak için çalışıyoruz.</p>
              <p className="small-content">
                - Kaliteli trafik oranlarını artırma<br />
                - Arama motoru sıralamanızı yükseltme<br />
                - Website gelirlerini artırma
        </p>
              {/* <p> <Link to="/#/" className="content-link">CRO sürecine göz atın.</Link></p> */}
            </div>
          </div>
        </div>

        <div className="services-list__inline py60 container">
          <div className="convert-service">
            <div>
              <img alt="alt text" src={webDev} ></img>
            </div>
            <div>
              <h2>Website<br />Geliştirmeleri</h2>
              <p>Dijital dünyaya açılan pencereniz olan websitenizi kullanıcılarınız için çok daha işlevsel hale getiriyor, potansiyel müşterilerinizin websitenizde daha fazla zaman geçirmesini sağlıyoruz.</p>
              <p className="small-content">
                - Trafik artışı<br />
                - Hemen ayrılma oranının düşürülmesi<br />
                - SEO uyumluluk
        </p>
              <p> <Link to="/web-mobil-gelistirme/" className="content-link">Website geliştirme sürecine göz atın.</Link></p>
            </div>

          </div>

          <div className="convert-service" id="lead-generation">
            <div>
              <img alt="alt text" src={LeadGen}></img>
            </div>
            <div>
              <h2>Lead <br />Generation</h2>
              <p>Şimdi veya gelecekte ürün ya da hizmetlerinizle ilgilenebilecek potansiyel müşterileri buluyor, daha sonra onlara uygun dijital pazarlama çalışmaları yürütüyoruz.</p>
              <p className="small-content">
                - Inbound pazarlama<br />
                - Talep üretme
            </p>
              {/* <p> <Link className="content-link">Lead generation sürecine göz atın.</Link></p> */}
            </div>
          </div>
        </div>
        <div className="check-modal">
          <span onClick={(e) => this.closeModal()}>X</span>
          <h2 className="form-head">Formu doldurun.</h2>             <p className="form-desc">Bilgi almak için bilgilerinizi formdaki alanlara girin, en kısa sürede size dönüş yapalım.</p>
          <div className="check-modal_container">
            <form onSubmit={this.handleSubmit} >
              <input type="text" value={this.state.value} onChange={this.handleChange} autoFocus={true} name="text_204202" id="site" required  placeholder="Websitenizi girin." />
              <input type="text" id="name" required name="text_775908" placeholder="Adınızı girin." />
              <input type="email" id="email" required  name="text_532192" placeholder="E-posta adresinizi girin." />
              <button>GÖNDER</button>
              <div className="status"></div>
            </form>
            <img alt="alt text" src={checkModal} />
          </div>
        </div>
      </Layout>
    )
  }
}
export default Convert
